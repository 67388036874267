import type { ParsedUrlQuery } from 'node:querystring';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, InputText } from '@i2e/components';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@i2e/forms';
import { VisibilityIcon, VisibilityOffIcon } from '@in2event/icons';
import Link from 'next/link';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import * as z from 'zod';

import { CheckboxLabel } from '@/components/checkbox-label';
import { ErrorMessageTextBox } from '@/components/message-text';

export interface PostPageQuery extends ParsedUrlQuery {
    r: string;
}

export function isPostPageQuery(query: unknown): query is PostPageQuery {
    return typeof query === 'object' && query !== null && 'r' in query;
}

const schema = z.object({
    email: z.string().email({ message: 'Invalid email address' }),
    password: z.string().min(1, { message: 'Password is Required' }),
    rememberUser: z.boolean(),
});

export type LoginFormFields = z.infer<typeof schema>;

interface LoginFormProps {
    onSubmitHandler: SubmitHandler<LoginFormFields>;
}

export const LoginForm = ({ onSubmitHandler }: LoginFormProps) => {
    const form = useForm<LoginFormFields>({
        resolver: zodResolver(schema),
        defaultValues: {
            email: '',
            password: '',
            rememberUser: false,
        },
    });

    const [passwordShown, setPasswordShown] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const onSubmit: SubmitHandler<LoginFormFields> = async (data) => {
        try {
            setLoading(true);
            await onSubmitHandler(data satisfies LoginFormFields);
            resetForm();
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setError(false);
        form.reset();
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem className="mb-5">
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <InputText placeholder="Email" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem className="mb-5">
                            <FormLabel>Password</FormLabel>
                            <div className="relative">
                                <FormControl>
                                    <InputText
                                        placeholder="Password"
                                        type={passwordShown ? 'text' : 'password'}
                                        {...field}
                                    />
                                </FormControl>
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-3.5"
                                    data-testid="visibility-toggle"
                                    onClick={togglePasswordVisibility}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {passwordShown ? (
                                        <VisibilityOffIcon className="size-5" />
                                    ) : (
                                        <VisibilityIcon className="size-5" />
                                    )}
                                </div>
                            </div>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className="mb-6 flex flex-wrap items-center justify-between gap-5">
                    <CheckboxLabel control={form.control} name="rememberUser">
                        Remember me
                    </CheckboxLabel>
                    <Link
                        className="text-sm text-sky-600 no-underline"
                        href="/reset-password"
                        prefetch={false}
                    >
                        Forgot password
                    </Link>
                </div>

                {error ? (
                    <div className="my-8">
                        <ErrorMessageTextBox text="Invalid email or password. Please try again." />
                    </div>
                ) : null}

                <Button
                    className="w-full items-center justify-center bg-sky-600"
                    loading={loading}
                    size="lg"
                    type="submit"
                    variant="primary"
                >
                    Continue
                </Button>
            </form>
        </Form>
    );
};
